import { FC } from 'react'
import styled, { css } from 'styled-components'
import { TKeywordList } from '../../types/keywordListType'

interface IFProps {
  keyword: string
  onClick: (keyword: TKeywordList) => void
}

const SelectLiteralButton: FC<IFProps> = ({ keyword, onClick }) => {
  const handleClick = () => {
    onClick({ name: keyword })
  }

  return (
    <StyledWrapper>
      <button
        className="button-input-value"
        onClick={handleClick}
        type="button"
      >
        {keyword} 직접입력
      </button>
    </StyledWrapper>
  )
}

export default SelectLiteralButton

const StyledWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    min-height: 47px;
    border-top: solid 1px ${theme.line.line_1};
    display: flex;
    align-items: center;
    justify-content: center;

    button {
      width: 100%;
      min-height: 20px;
      border: 0;
      background-color: transparent;
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
      letter-spacing: -0.56px;
      text-align: center;
      color: ${theme.color.primary_2};
      text-decoration: underline;
      cursor: pointer;
      display: block;
      overflow-wrap: break-word;
    }
  `}
`
