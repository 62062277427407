import { useRouter } from 'next/router'
import styled, { css } from 'styled-components'
import FormLabel from '../../desktop/components/FormLabel'
import InputPhone from './FieldPhoneNumber/InputPhone'

interface IFProps {
  isRequired?: boolean
  subTitle?: string
}
const FieldPhone = ({ isRequired = true, subTitle }: IFProps) => {
  const { query } = useRouter()
  const userPhoneNumber = query.phoneNumber
    ? (query.phoneNumber as string).replace(/[^0-9]/g, '')
    : ''

  return (
    <StyledWrapper>
      <FormLabel
        label="휴대폰번호"
        isRequired={isRequired}
        subTitle={subTitle}
      />
      {userPhoneNumber ? (
        <div className="entered-phone">{userPhoneNumber}</div>
      ) : (
        <InputPhone />
      )}
    </StyledWrapper>
  )
}
export default FieldPhone

const StyledWrapper = styled.div`
  ${({ theme }) => css`
    display: grid;
    gap: 9px;
    > .entered-phone {
      display: flex;
      align-items: center;
      height: 50px;
      padding: 0 16px;
      border-radius: 8px;
      border: solid 1px ${theme.line.line_2};
      font-size: 16px;
      background: ${theme.background.bg_3};
      margin-bottom: 17px;
    }
  `}
`
