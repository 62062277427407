import { ChangeEvent, InputHTMLAttributes, forwardRef } from 'react'
import styled, { css } from 'styled-components'
import MagnifyingGlassIcon from 'svgIcons/MagnifyingGlass'

interface IFProps extends InputHTMLAttributes<HTMLInputElement> {
  errorMessage?: string
  onChangeKeyword: (value: string) => void
}

const InputKeyword = forwardRef<HTMLInputElement, IFProps>(
  ({ errorMessage, onChangeKeyword, ...restProps }, searchKeywordRef) => {
    const { onChange, ...restInputProps } = restProps

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      const value = e.target.value.trim()
      e.target.value = value
      onChangeKeyword(value)
      if (onChange) {
        onChange(e)
      }
    }

    return (
      <StyledWrapper>
        <input
          type="text"
          className={errorMessage ? 'error' : ''}
          ref={searchKeywordRef}
          onChange={handleChange}
          autoComplete="off"
          {...restInputProps}
        />
        <MagnifyingGlassIcon className="icon-postcode-search" />
        {errorMessage && <p className="error-message">{errorMessage}</p>}
      </StyledWrapper>
    )
  },
)

export default InputKeyword

const StyledWrapper = styled.div`
  ${({ theme }) => css`
    & > input {
      width: 100%;
      height: 50px;
      border-radius: 8px;
      border: solid 1px ${theme.line.line_2};
      background-color: ${theme.background.bg_8};
      font-size: 16px;
      font-weight: 500;
      line-height: 48px;
      letter-spacing: -0.64px;
      text-align: left;
      color: ${theme.color.text_1};
      padding: 13px 35px 13px 15px;

      &:focus {
        border: 1px solid ${theme.color.primary_2};
        outline: none;
      }
      &.error {
        color: ${theme.color.error_1};
        border: solid 1px ${theme.color.error_1};
      }
      &::placeholder {
        color: ${theme.color.text_3};
      }
    }

    & > .icon-postcode-search {
      width: 16px;
      height: 16px;
      position: absolute;
      top: 17px;
      right: 13px;
    }

    & > .error-message {
      height: 17px;
      font-size: 12px;
      font-weight: normal;
      line-height: 17px;
      letter-spacing: -0.48px;
      text-align: left;
      color: ${theme.color.error_1};
      margin-top: 10px;
    }
  `}
`
