import { FC } from 'react'
import styled, { css } from 'styled-components'

interface IFProps {
  label: string
  canMultiple?: boolean
  subTitle?: string
  isRequired?: boolean
}

const FormLabel: FC<IFProps> = ({
  label,
  canMultiple,
  subTitle,
  isRequired = true,
}) => {
  return (
    <StyledWrapper>
      <p className={isRequired ? 'required' : ''}>
        {label}
        {canMultiple && <span>&nbsp;(중복 선택 가능)</span>}
      </p>
      {subTitle && <p className="sub-title">{subTitle}</p>}
    </StyledWrapper>
  )
}

export default FormLabel

const StyledWrapper = styled.div`
  ${({ theme }) => css`
    display: flex;
    gap: 4px;
    > p {
      font-size: 13px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: -0.52px;
      color: #333;
      span {
        color: ${theme.color.text_2};
      }
      &.required::after {
        content: '*';
        color: ${theme.color.error_1};
        padding-left: 4px;
      }
    }
    > .sub-title {
      font-size: 12px;
      letter-spacing: -0.48px;
      color: ${theme.color.text_2};
    }
  `}
`
