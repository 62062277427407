import { FC } from 'react'
import styled, { css } from 'styled-components'
import { TKeywordList } from '../types/keywordListType'
import EmptyListItem from './components/EmptyListItem'
import KeywordListItem from './components/KeywordListItem'
import SelectLiteralButton from './components/SelectLiteralButton'

interface IFProps {
  keywordListData: Array<TKeywordList>
  keyword: string
  onSelectKeywordItem: (selectValue: TKeywordList) => void
}

const KeywordList: FC<IFProps> = ({
  keywordListData = [],
  keyword,
  onSelectKeywordItem,
}) => {
  const handleSelectItem = (keyword: TKeywordList) => {
    onSelectKeywordItem(keyword)
  }
  const isListEmpty = keywordListData.length === 0
  return (
    <StyledWrapper>
      <ul>
        {isListEmpty && <EmptyListItem />}
        {keywordListData.map((item) => {
          return (
            <KeywordListItem
              itemName={item.name}
              dataId={item.id}
              key={`search-keyword-key-${item.id}`}
              onClickItem={handleSelectItem}
            />
          )
        })}
      </ul>
      <SelectLiteralButton keyword={keyword} onClick={handleSelectItem} />
    </StyledWrapper>
  )
}

export default KeywordList

const StyledWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    position: absolute;
    top: 54px;
    z-index: 30;
    background-color: ${theme.background.bg_8};
    border-radius: 8px;
    border: solid 1px ${theme.line.line_1};

    & > ul {
      min-height: 130px;
      max-height: 230px;
      padding-top: 8px;
      overflow-y: auto;
    }
  `}
`
