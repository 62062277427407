import styled from 'styled-components'
import { useGqlJobCategoriesQuery } from 'generated/graphql'
import FormLabel from '@app.feature/signup/signup/desktop/components/FormLabel'
import JobBlock from './components/JobBlock'

const FieldJob = () => {
  const { data, loading, error } = useGqlJobCategoriesQuery()

  return (
    <StyledWrapper>
      <FormLabel label="관심 직무" canMultiple={true} />
      {!(loading || error) && (
        <div>
          {data?.jobCategories.map((item) => {
            return <JobBlock key={item.id} item={item} />
          })}
        </div>
      )}
    </StyledWrapper>
  )
}
export default FieldJob

const StyledWrapper = styled.article`
  width: 100%;
  display: grid;
  gap: 16px;
`
