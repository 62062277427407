import { ChangeEvent, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { ApolloError } from '@apollo/client'
import { useRouter } from 'next/router'
import styled, { css } from 'styled-components'
import { useGenerateSmsAuthCodeMutation } from 'generated/graphql'
import { SignupModalType } from '@app.feature/home/type/signupModalType'
import { changeInputToOnlyNumber } from '@app.feature/signup/signup/module/utils'
import { SignupFormType } from '@app.feature/signup/signup/types/signupFormType'
import ERROR_MESSAGE from 'constants/errorMessages'
import useErrorSnackbar from 'hooks/useErrorSnackbar'
import { getErrorCode } from 'utils/apolloErrorParser'
import FieldVerification from './FieldVerification'
import PhoneNumberButton from './PhoneNumberButton'

const InputPhone = () => {
  const {
    watch,
    register,
    clearErrors,
    setValue,
    setError,
    formState: { errors },
  } = useFormContext<SignupFormType | SignupModalType>()
  const { enqueueApolloError } = useErrorSnackbar()

  const inputPhoneNumber = watch('phoneInput') || ''
  const [isVerificationSent, setIsVerificationSent] = useState(false)
  const [fieldVerificationField, setFieldVerificationKey] = useState(0) // FieldVerification 재호출에 사용하는 key값
  const [isVerified, setIsVerified] = useState(false)
  const [verificationToken, setVerificationToken] = useState('')

  const { query } = useRouter()
  const [generateSmsAuthCode] = useGenerateSmsAuthCodeMutation({
    onCompleted: (data) => {
      setVerificationToken(data.generateSmsAuthCode.token)
      setError('phoneVerification', {
        message: '',
      })
    },
    onError: (err: ApolloError) => {
      const errorCode = getErrorCode(err)
      if (errorCode) {
        setError('phoneInput', {
          message: ERROR_MESSAGE[errorCode],
        })
      } else {
        enqueueApolloError()
      }
    },
  })

  const handlePhoneInput = (e: ChangeEvent<HTMLInputElement>) => {
    if (isVerificationSent) {
      setIsVerificationSent(false)
      clearErrors('phoneVerification')
    }
    changeInputToOnlyNumber(e)
  }

  const getButtonState = () => {
    if (isVerified) {
      return 'verified'
    } else if (isVerificationSent || inputPhoneNumber.length >= 10) {
      return 'active'
    } else {
      return 'inactive'
    }
  }
  const handleClickSent = async () => {
    await generateSmsAuthCode({
      variables: {
        input: {
          phoneNumber: inputPhoneNumber,
          token: query.token as string,
        },
      },
    })
    if (!errors.phoneInput?.message) {
      setIsVerificationSent(true)
    }
    setValue('phoneVerification', '')
    setFieldVerificationKey((prev) => prev + 1)
  }

  const handleClickVerify = () => {
    setValue('phoneNumber', inputPhoneNumber, { shouldValidate: true })
    setIsVerified(true)
  }

  return (
    <StyledWrapper>
      <div className="input-phone-container">
        <input
          type="text"
          maxLength={11}
          className={'phone-input'}
          placeholder={`'-' 제외하고 입력`}
          autoComplete="off"
          disabled={isVerified}
          {...register('phoneInput', {
            onChange: handlePhoneInput,
          })}
        />
        <PhoneNumberButton
          status={getButtonState()}
          disabled={inputPhoneNumber.length < 10 || isVerified}
          buttonText={isVerificationSent ? '재발송' : '인증하기'}
          onClickButton={handleClickSent}
        />
      </div>
      {isVerificationSent && (
        <FieldVerification
          onClickVerify={handleClickVerify}
          isVerified={isVerified}
          key={fieldVerificationField}
          verificationToken={verificationToken}
        />
      )}
      <span
        className={`input-message ${
          errors.phoneInput?.message || errors.phoneVerification?.message
            ? 'error-message'
            : isVerified && 'verified-message'
        }`}
      >
        {errors.phoneInput?.message || errors.phoneVerification?.message}
        {isVerified && '인증이 완료되었습니다'}
      </span>
    </StyledWrapper>
  )
}

export default InputPhone

const StyledWrapper = styled.div`
  ${({ theme }) => css`
    position: relative;
    .input-phone-container {
      display: flex;
      gap: 5px;
      > .phone-input {
        flex: 1;
        height: 50px;
        padding: 0 16px;
        border-radius: 8px;
        border: solid 1px ${theme.line.line_2};
        font-size: 16px;
        &:focus {
          outline: none;
          border: solid 1px ${theme.color.primary_2};
        }
      }
    }

    > span.input-message {
      display: block;
      height: 17px;
      font-size: 12px;
      font-weight: normal;
      line-height: 17px;
      letter-spacing: -0.48px;
      &.error-message {
        color: ${theme.color.error_1};
      }
      &.verified-message {
        color: ${theme.color.primary_2};
      }
    }
  `}
`
