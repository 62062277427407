import { ButtonHTMLAttributes } from 'react'
import styled, { css } from 'styled-components'

interface IFProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  status: string
  buttonText: string
  onClickButton: () => void
}
const PhoneNumberButton = ({
  status,
  buttonText,
  onClickButton,
  ...restProps
}: IFProps) => {
  return (
    <StyledWrapper
      type="button"
      className={`phone-button ${status}`}
      onClick={onClickButton}
      {...restProps}
    >
      {buttonText}
    </StyledWrapper>
  )
}

export default PhoneNumberButton

const StyledWrapper = styled.button`
  ${({ theme }) => css`
    min-width: 83px;
    height: 50px;
    border: none;
    cursor: pointer;
    padding: 13px 20px;
    border-radius: 8px;
    font-size: 16px;
    font-weight: bold;
    letter-spacing: -0.64px;
    color: white;
    &[disabled] {
      cursor: not-allowed;
    }
    &.inactive {
      background-color: #bbb;
    }
    &.active {
      background: ${theme.color.primary_2};
    }
    &.verified {
      background-color: #bbb;
    }
  `}
`
