import { FC, MouseEvent } from 'react'
import styled, { css } from 'styled-components'
import { TKeywordList } from '../../types/keywordListType'

interface IFProps {
  itemName: string
  dataId?: string
  onClickItem: (value: TKeywordList) => void
}

const KeywordListItem: FC<IFProps> = ({ dataId, itemName, onClickItem }) => {
  const handleClick = (e: MouseEvent<HTMLLIElement>) => {
    const selectName = e.currentTarget.dataset.selectName || ''
    const selectId = e.currentTarget.dataset.selectId || ''
    onClickItem({ id: selectId, name: selectName })
  }

  return (
    <StyledWrapper
      onClick={handleClick}
      data-select-name={itemName}
      data-select-id={dataId}
    >
      <p>{itemName}</p>
    </StyledWrapper>
  )
}

export default KeywordListItem

const StyledWrapper = styled.li`
  ${({ theme }) => css`
    height: 40px;
    display: flex;
    align-items: center;
    padding: 15px 0;
    cursor: pointer;

    &:hover {
      background-color: ${theme.background.bg_3};
    }

    & > p {
      width: 100%;
      height: 20px;
      padding-left: 13px;
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
      letter-spacing: -0.56px;
      text-align: left;
      color: ${theme.color.text_1};
    }
  `}
`
