import { ApolloError } from '@apollo/client'
import get from 'lodash/get'
import errorMessages from '../constants/errorMessages'

export const getErrorCode = (error?: Partial<ApolloError>): string => {
  const errorCode: string = get(
    error,
    'graphQLErrors[0].extensions.code',
    'UNKNOWN_ERROR',
  )

  return errorCode
}

export const getErrorMessage = (
  error?: Partial<ApolloError> | unknown,
): string => {
  const errorCode = getErrorCode(error as Partial<ApolloError>)
  const message: string = get(
    errorMessages,
    errorCode,
    errorMessages.UNKNOWN_ERROR,
  )

  return message
}

export const getErrorMessageFromServer = (
  error?: Partial<ApolloError>,
): string => {
  const message: string = get(
    error,
    'graphQLErrors[0].message',
    errorMessages.UNKNOWN_ERROR,
  )

  return message
}
