import { useFormContext } from 'react-hook-form'
import styled, { css } from 'styled-components'
import { Check } from 'svgIcons'

interface IProps {
  item: {
    id: string
    name: string
  }
}
const JobBlock = ({ item }: IProps) => {
  const { register } = useFormContext()
  const { id, name } = item
  return (
    <StyledWrapper>
      <input
        type="checkbox"
        id={`job-${name}`}
        value={id}
        {...register('preferJobCategoryIds')}
      />
      <label htmlFor={`job-${name}`} className="pointer">
        <p>{name}</p>
        <i className="check-svg">
          <Check className="check-icon" color="#01a0ff" width={12} />
        </i>
      </label>
    </StyledWrapper>
  )
}
export default JobBlock

const StyledWrapper = styled.div`
  ${({ theme }) => css`
    height: 40px;
    display: inline-block;
    flex-direction: row;
    justify-content: center;
    margin: 0 4px 8px 0;
    > input {
      display: none;
      &:checked + label {
        color: ${theme.color.primary_2};
        border: solid 1px ${theme.color.primary_2};
        background-color: ${theme.background.bg_4};
        > .check-svg {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 20px;
        }
      }
    }
    > label {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 10px 15px;
      border-radius: 8px;
      border: solid 1px ${theme.line.line_2};
      > p {
        height: 20px;
        font-size: 14px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        letter-spacing: -0.56px;
      }
      > .check-svg {
        display: none;
      }
    }
  `}
`
