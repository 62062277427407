import { useCallback, useRef } from 'react'

type DebounceCallback = (value: string) => void

/**
 * @desc 검색어 입력 debounce hook
 * @returns debounce callback
 */
export const useDebounce = (
  callback: (value: string) => void,
  delay: number,
): DebounceCallback => {
  const timeoutRef = useRef<ReturnType<typeof setTimeout> | null>(null)

  return useCallback(
    (value: string) => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current)
      }

      timeoutRef.current = setTimeout(() => {
        if (value !== '') {
          callback(value)
        }
      }, delay)
    },
    [callback, delay],
  )
}
