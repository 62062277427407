import { useState, useEffect } from 'react'

type TUseTimer = (time: number) => {
  leftTime: string
  isExpire: boolean
  handleClearTimer: () => void
  isCleared: boolean
}

/**
 * TODO : 추후 추상화하여 모듈화 하기
 * @desc 이메인 인증 코드의 입력 시간 타이머를 적용합니다
 * @desc time ex) 60분 = 60 * 60, 59분 59초 = 60 * 59 + 59
 * @param time 타이머 시작 시간
 * @returns
 */
const useTimer: TUseTimer = (time: number) => {
  const [timeLeft, setTimeLeft] = useState(time)
  const [isExpire, setIsExpire] = useState<boolean>(false)
  const [isCleared, setIsCleared] = useState(false)

  useEffect(() => {
    if (isCleared) {
      return
    }
    const interval = setInterval(() => {
      setTimeLeft((prevTime) => prevTime - 1)
    }, 1000)

    if (timeLeft < 1) {
      clearInterval(interval)
      setIsExpire(true)
    }

    return () => clearInterval(interval)
  }, [timeLeft])

  const handleClearTimer = () => {
    setIsCleared(true)
  }

  const minutes = String(Math.floor(timeLeft / 60)).padStart(2, '0')
  const seconds = String(timeLeft % 60).padStart(2, '0')

  const leftTime = `${minutes}:${seconds}`

  return { leftTime, isExpire, handleClearTimer, isCleared }
}

export default useTimer
