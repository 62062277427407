export default {
  INVALID_REQUEST: '올바르지 않은 요청 입니다.',
  NO_RESPONSE_ERROR: '현재 서버에 응답이 없습니다. 잠시후 다시 시도해주세요',
  UNKNOWN_ERROR: '알 수 없는 오류가 발생했습니다.',
  FORBIDDEN: '요청할 수 있는 권한이 없습니다.',
  UNAUTHENTICATED:
    '계정이 인증되지 않았습니다. 로그인 확인 후 다시 시도해 보세요.',
  REQUIRED_LOGGED_IN: '로그인 후 사용할 수 있습니다.',
  NOT_FOUND_ERROR: '알 수 없는 오류가 발생했습니다.',

  ALREADY_EXISTING_EMAIL:
    '이미 가입된 이메일 입니다. 다른 이메일을 사용해주세요',
  ALREADY_EXISTING_NICKNAME:
    '이미 가입된 닉네임 입니다. 다른 닉네임을 사용해주세요',
  ALREADY_EXISTING_PHONE_NUMBER:
    '입력하신 휴대폰 번호는 이미 가입된 회원입니다.',
  INVALID_PHONE_NUMBER_FORMAT: '번호를 올바르게 입력해주세요',
  INVALID_AUTH_CODE: '인증번호가 일치하지 않습니다. 다시 확인해주세요.',

  ALREADY_JOINED_CHAT_ROOM: '이미 참여한 채팅방입니다.',
  TIMESPREAD_STATUS_REQUIRE_PARAMETER: '필요 파리미터 누락',
  TIMESPREAD_STATUS_OBJECT_NOT_EXIST: '일치하는 대상 없음',
  TIMESPREAD_STATUS_PHONE_ALREADY_AUTHORIZE: '이미 인증된 핸드폰 번호입니다.',
  TIMESPREAD_STATUS_PHONE_CODE_REQUEST_EXCEED:
    '휴대폰 인증 코드 요청 횟수가 초과 하였습니다.',
  TIMESPREAD_STATUS_PHONE_CODE_DATA_NOT_FOUND: '휴대폰 인증 코드 데이터 없음',
  TIMESPREAD_STATUS_PHONE_FAIL_AUTHORIZE: '휴대폰 인증에 실패 하였습니다.',
  TIMESPREAD_STATUS_PHONE_REQUEST_EXCEED: '휴대폰 인증 횟수가 초과 하였습니다.',
  TIMESPREAD_STATUS_PHONE_NOT_ACCEPTED_IP: '허용되지 않은 IP',
  TIMESPREAD_STATUS_PHONE_USER_DELETE: '탈퇴 후 7일 이후부터 휴대폰 인증 가능',
  TIMESPREAD_STATUS_LUCKYBOX_LIMIT_EXCEED: '행운상자 개수 초과',
  TIMESPREAD_STATUS_LUCKYBOX_NEED_CASH: '행운상자 도전 최소 캐시 필요',
  TIMESPREAD_STATUS_LUCKYBOX_ALREADY_OPEN: '이미 오픈된 상자',
  TIMESPREAD_STATUS_SCREEN_ALREADY_FINISH: '이미 종료된 꽉 잠금 모드',
}
