import { useFormContext } from 'react-hook-form'
import styled, { css } from 'styled-components'

interface IFProps {
  buttonText?: string
}
const SignupSubmitButton = ({ buttonText = '가입 완료' }: IFProps) => {
  const {
    formState: { errors, isValid, isSubmitting, isSubmitted },
  } = useFormContext()

  const canSubmit =
    !Object.keys(errors).length && isValid && !isSubmitting && !isSubmitted
  return (
    <StyledWrapper type="submit" disabled={!canSubmit}>
      {buttonText}
    </StyledWrapper>
  )
}

export default SignupSubmitButton
const StyledWrapper = styled.button`
  ${({ theme }) => css`
    width: 100%;
    height: 50px;
    margin-top: 20px;
    border: none;
    border-radius: 8px;
    color: white;
    background-color: ${theme.color.primary_2};
    font-family: NotoSansKR;
    font-size: 16px;
    font-weight: bold;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: -0.64px;
    text-align: center;
    cursor: pointer;
    &:disabled {
      background-color: #bbbbbb;
      cursor: default;
    }
  `}
`
