import { FC, InputHTMLAttributes, forwardRef, useRef, useState } from 'react'
import styled from 'styled-components'
import { useOutSideClick } from '@app.feature/careerManage/hook/useOutSideClick'
import { useDebounce } from '@app.feature/signup/signup/module/useDebounce'
import InputKeyword from './components/InputKeyword'
import KeywordList from './keywordList/KeywordList'
import { TKeywordList } from './types/keywordListType'

interface IFProps extends InputHTMLAttributes<HTMLInputElement> {
  keywordList?: Array<TKeywordList>
  onSelectKeywordOption?: (value: TKeywordList) => void
  onKeywordQuery: (value: string) => void
}
const InputSearchKeyword: FC<IFProps> = forwardRef<HTMLInputElement, IFProps>(
  (
    { keywordList, onSelectKeywordOption, onKeywordQuery, ...restInputProps },
    searchKeywordRef,
  ) => {
    const outSideWrapperRef = useRef<HTMLDivElement>(null)
    const [isShowInputSearch, setIsShowInputSearch] = useState<boolean>(false)
    const [literalKeyword, setLiteralKeyword] = useState<string>('')

    const debouncedCallback = useDebounce((keyword) => {
      setLiteralKeyword(() => keyword)
      onKeywordQuery(keyword)
    }, 300)

    const handleChangeKeyword = (value: string) => {
      setIsShowInputSearch(!!value)
      debouncedCallback(value)
    }

    const handleSelectKeywordItem = (selectValue: TKeywordList) => {
      if (onSelectKeywordOption) {
        onSelectKeywordOption(selectValue)
        setIsShowInputSearch(false)
      }
    }
    const handleOutsideClick = () => {
      setIsShowInputSearch(false)
    }

    useOutSideClick({
      ref: outSideWrapperRef,
      isActive: isShowInputSearch,
      onOutsideClick: handleOutsideClick,
    })

    return (
      <StyledWrapper ref={outSideWrapperRef}>
        <InputKeyword
          onChangeKeyword={handleChangeKeyword}
          ref={searchKeywordRef}
          {...restInputProps}
        />
        {isShowInputSearch && (
          <KeywordList
            keyword={literalKeyword}
            keywordListData={keywordList || []}
            onSelectKeywordItem={handleSelectKeywordItem}
          />
        )}
      </StyledWrapper>
    )
  },
)

export default InputSearchKeyword

const StyledWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`
