import { MouseEvent } from 'react'
import styled, { css } from 'styled-components'

interface IFDropdown {
  label: string
  value: string
}

interface SelectDropdownProps {
  options: IFDropdown[]
  onClickOption: (selectedOption: string) => void
}
const SelectDropdown = ({ options, onClickOption }: SelectDropdownProps) => {
  const handleClickOption = (e: MouseEvent<HTMLLIElement>) => {
    onClickOption(e.currentTarget.dataset.selectValue || '')
  }
  return (
    <StyledWrapper>
      {options.map((option) => (
        <li
          key={option.value}
          data-select-value={option.value}
          onClick={handleClickOption}
        >
          {option.label}
        </li>
      ))}
    </StyledWrapper>
  )
}
export default SelectDropdown

const StyledWrapper = styled.ul`
  ${({ theme }) => css`
    width: 100%;
    height: fit-content;
    max-height: 320px;
    overflow-y: auto;
    border: solid 1px ${theme.line.line_1};
    border-radius: 8px;
    background-color: ${theme.background.bg_8};
    position: absolute;
    left: 0;
    top: 54px;
    z-index: 10;

    & > li {
      height: 40px;
      display: flex;
      align-items: center;
      padding: 10px 15px;

      &:hover {
        background-color: ${theme.background.bg_3};
      }

      p {
        height: 20px;
        font-size: 14px;
        font-weight: normal;
        line-height: 20px;
        letter-spacing: -0.56px;
        text-align: left;
        color: ${theme.color.text_1};

        &:not(:first-child) {
          margin-left: 5px;
        }
      }
    }
  `}
`
