import styled from 'styled-components'
import FieldActivity from './interestActivity/FieldActivity'
import FieldJob from './interestJob/FieldJob'

const SignupInterest = () => {
  return (
    <StyledWrapper>
      <FieldActivity />
      <FieldJob />
    </StyledWrapper>
  )
}

export default SignupInterest
const StyledWrapper = styled.div`
  display: grid;
  margin-top: 28px;
  gap: 28px;
`
