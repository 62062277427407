import styled, { css } from 'styled-components'

const EmptyListItem = () => {
  return (
    <StyledWrapper aria-describedby="검색 결과가 존재하지 않습니다.">
      <span>일치하는 조건이 없습니다.</span>
    </StyledWrapper>
  )
}

export default EmptyListItem

const StyledWrapper = styled.li`
  ${({ theme }) => css`
    height: 40px;
    display: flex;
    align-items: center;
    padding: 15px 10px;

    & > span {
      width: 100%;
      height: 20px;
      padding-left: 13px;
      font-size: 14px;
      font-weight: normal;
      line-height: 20px;
      letter-spacing: -0.56px;
      text-align: left;
      color: ${theme.color.text_1};
    }
  `}
`
