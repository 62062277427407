import { ChangeEvent } from 'react'

/**
 * @desc 0-9까지의 숫자만 입력받을 수 있도록 처리합니다
 */
export const changeInputToOnlyNumber = (e: ChangeEvent<HTMLInputElement>) => {
  const inputText = e.target.value.trim().replace(/[^0-9]/g, '')

  e.target.value = inputText
}
