import styled, { css } from 'styled-components'
import ExpandMoreIcon from 'svgIcons/ExpandMore'

interface SelectBoxProps {
  onClick: () => void
  selectedValue: string
}
const SelectBox = ({ onClick: handleClick, selectedValue }: SelectBoxProps) => {
  return (
    <StyledWrapper onClick={handleClick}>
      {selectedValue ? (
        <p>{selectedValue}</p>
      ) : (
        <p aria-describedby="placeholder">선택</p>
      )}
      <i className="icon-arrow">
        <ExpandMoreIcon />
      </i>
    </StyledWrapper>
  )
}
export default SelectBox

const StyledWrapper = styled.div`
  ${({ theme }) => css`
    width: 100%;
    height: 50px;
    padding: 0 16px;
    border-radius: 8px;
    border: solid 1px ${theme.line.line_2};
    font-size: 15px;
    display: flex;
    align-items: center;
    > p {
      flex: 1;
      letter-spacing: -0.64px;
      text-align: left;
      &[aria-describedby='placeholder'] {
        color: ${theme.color.text_2};
      }
    }
  `}
`
